@import './Draggable/index.css';
@import './Droppable/index.css';
@import './ErrorCell/index.css';
@import './Handle/index.css';

/* general rules for read only and edit */

.react-page-cell {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  &-inline-left {
    float: left;
  }

  &-inline-right {
    float: right;
  }

  &-has-inline-neighbour {
    float: none;
    width: 100%;
  }

  &-has-inline-neighbour > .react-page-cell {
    display: block;
  }

  & &-inner {
    height: 100%;
    box-sizing: border-box;
    outline: none;
  }
}

/* edit */
.react-page-editable {
  &&-mode-edit,
  &&-mode-resizing,
  &&-mode-layout {
    .react-page-cell {
      position: relative;
      transition: box-shadow 0.3s;

      &-is-draft {
        opacity: 0.3;
        outline: 1px dashed black;
      }
      &-bring-to-front {
        z-index: 3;
      }
      &.react-page-cell-has-plugin:hover {
        z-index: 2;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
        opacity: 1;
      }
      &.react-page-cell-focused.react-page-cell-focused {
        z-index: 1;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.4);
        opacity: 1;
      }
    }
  }

  &&-mode-insert {
    .react-page-cell {
      cursor: cell;
      &:hover {
        z-index: 1;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.4);
        opacity: 1;
      }
    }
  }
}
